<template>
  <div>
    <pm-Card>
      <template v-slot:title> Login e dados pessoais </template>
      <template v-slot:subtitle> Insira os dados abaixo </template>
      <template v-slot:content>
        <div class="p-fluid formgrid grid">
          <div class="field col-12 md:col-2">
            <label for="firstname">Email:</label>
            <pm-InputText
              id="email"
              @change="validateForm"
              :style="greenColor"
              v-model="info.email"
              v-on:blur="verificarEmail"
              :class="{ 'p-invalid': validationErrors.email && submitted }"
            />
            <small v-show="validationErrors.email && submitted" class="p-error"
            >O email é obrigatório!</small
            >
            <small v-show="emailInvalidoJaExiste" class="p-error"
            >O Email digitado já está em uso!</small
            >
          </div>
          <div class="field col-12 md:col-2">
            <label for="senha">Senha:</label>
            <pm-Password
              v-model="info.senha"
              weakLabel="Fraca"
              mediumLabel="Média"
              strongLabel="Forte"
              toggleMask
              :class="{
                'p-invalid':
                  validationErrors.senha &&
                  validationErrors.senhaObrigatoria &&
                  submitted,
              }"
            ></pm-Password>
            <small v-show="validationErrors.senha && submitted" class="p-error"
            >As Senhas não coincidem!</small
            >
            <small
              v-show="validationErrors.senhaObrigatoria && submitted"
              class="p-error"
            >A Senha é obrigatória!</small
            >
          </div>
          <div class="field col-12 md:col-2">
            <label for="senha">Confirmar senha</label>
            <pm-Password
              v-model="info.confirmarSenha"
              weakLabel="Fraca"
              mediumLabel="Média"
              strongLabel="Forte"
              toggleMask
              :class="{
                'p-invalid': validationErrors.confirmarSenha && submitted,
              }"
            ></pm-Password>
            <small
              v-show="validationErrors.confirmarSenha && submitted"
              class="p-error"
            >Senhas não coincidem!</small
            >
          </div>
          <div class="field col-12 md:col-2">
            <label for="funcao">Função:</label>
            <pm-Dropdown
              v-model="info.funcao"
              @change="validateForm"
              :options="funcaoALL"
              optionLabel="nome"
              placeholder="Selecione uma função"
              :class="{ 'p-invalid': validationErrors.funcao && submitted }"
            />
            <small v-show="validationErrors.funcao && submitted" class="p-error"
            >A função é obrigatória!</small
            >
          </div>
          <div class="field col-12 md:col-2">
            <label for="senha">Adicionar celular:</label>
            <pm-InputText
              id="celular"
              v-model="celular1"
              placeholder="Digite o número"
              @keyup.enter="adicionarTelefone(1)"
              v-mask="{ mask: '(NN) NNNNN-NNNN' }"
            />
          </div>

          <div class="field col-12 md:col-2">
            <label for="senha">À quem pertence o celular ? </label>
            <pm-Dropdown
              :style="errorColor"
              v-model="selected"
              :options="selecIdentificador"
            />
            <small v-if="isNull" v-show="isNull" class="p-error"
            >Selecione um campo.
            </small>
          </div>
        </div>

        <pm-DataTable
          :value="info.telefone"
          v-if="info.telefone.length"
          dataKey="nome"
          :rowHover="true"
          filterDisplay="menu"
          v-model:filters="filters"
          :globalFilterFields="['nome']"
          responsiveLayout="scroll"
        >
          <template #header> Você pode editar ou deletar um número </template>
          <pm-Column
            field="nome"
            sortable
            header="Celular"
            style="font-size: 14px; vertical-align: middle !important"
          >
          </pm-Column>
          <pm-Column
            field="identificador"
            sortable
            header="Possuinte"
            style="font-size: 14px; vertical-align: middle !important"
          >
          </pm-Column>
          <pm-Column
            field="nome"
            headerStyle="width: 4rem; text-align: center"
            bodyStyle="text-align: center; overflow: visible;width: 357px;vertical-align:middle !important"
          >
            <template #body="{ data }">
              <pm-Button
                type="button"
                @click="oTelefoneSeraCadastradoOuEditado(2, data.nome)"
                icon="pi pi-pencil"
                label="Editar"
                title="Editar"
                class="p-button-sm btn-color"
              ></pm-Button>
              <pm-Button
                title="Apagar"
                type="button"
                @click="deletarCelular(data)"
                label="Apagar"
                icon="pi pi-times-circle"
                class="p-button-danger p-button-sm btn-color"
                style="margin-left: 5px"
              ></pm-Button>
            </template>
          </pm-Column>
        </pm-DataTable>
        <br />
        <hr />
        <div class="p-fluid formgrid grid" v-if="this.info.id">
          <div class="field col-12 md:col-12">
            <h6>Permissões Especiais</h6>
          </div>
          <div class="field col-12 md:col-12">
            <pm-DataTable
              :value="modulos"
              dataKey="nome"
              :rowHover="true"
              filterDisplay="menu"
              v-model:filters="filters"
              :globalFilterFields="['nome']"
              responsiveLayout="scroll"
            >
              <pm-Column
                field="modulo"
                sortable
                header="Modulos"
                style="font-size: 14px; vertical-align: middle !important"
              >
              </pm-Column>
              <pm-Column
                field="especial"
                header="CONFIGURACÕES"
                style="font-size: 14px; vertical-align: middle !important"
              >
                <template #body="data">
                  <div class="p-fluid formgrid grid">
                    <div
                      class="field col-12 md:col-3"
                      style="margin-bottom: 0rem"
                    >
                      <label class="form-check-label" v-if="data.data.especial">
                        <input
                          type="checkbox"
                          v-if="data.data.especial.visualizar == 1"
                          checked
                          @change="
                            modulo(
                              'visualizar',
                              data.data.permissao_modulo_id,
                              visualizar[data.index]
                            )
                          "
                          class="form-check-input"
                          style="margin-right: 10px"
                          v-model="visualizar[data.index]"
                        />
                        <input
                          type="checkbox"
                          v-else
                          @change="
                            modulo(
                              'visualizar',
                              data.data.permissao_modulo_id,
                              visualizar[data.index]
                            )
                          "
                          class="form-check-input"
                          style="margin-right: 10px"
                          v-model="visualizar[data.index]"
                        />
                        Visualizar
                      </label>
                      <label class="form-check-label" v-else>
                        <input
                          type="checkbox"
                          checked
                          @change="
                            modulo(
                              'visualizar',
                              data.data.permissao_modulo_id,
                              visualizar[data.index]
                            )
                          "
                          class="form-check-input"
                          style="margin-right: 10px"
                          v-model="visualizar[data.index]"
                        />
                        Visualizar
                      </label>
                    </div>

                    <div
                      class="field col-12 md:col-3"
                      style="margin-bottom: 0rem"
                    >
                      <label class="form-check-label" v-if="data.data.especial">
                        <input
                          type="checkbox"
                          v-if="data.data.especial.cadastrar == 1"
                          checked
                          @change="
                            modulo(
                              'cadastrar',
                              data.data.permissao_modulo_id,
                              cadastrar[data.index]
                            )
                          "
                          class="form-check-input"
                          style="margin-right: 10px"
                          v-model="cadastrar[data.index]"
                        />
                        <input
                          type="checkbox"
                          v-else
                          @change="
                            modulo(
                              'cadastrar',
                              data.data.permissao_modulo_id,
                              cadastrar[data.index]
                            )
                          "
                          class="form-check-input"
                          style="margin-right: 10px"
                          v-model="cadastrar[data.index]"
                        />
                        Cadastrar
                      </label>
                      <label class="form-check-label" v-else>
                        <input
                          type="checkbox"
                          checked
                          @change="
                            modulo(
                              'cadastrar',
                              data.data.permissao_modulo_id,
                              cadastrar[data.index]
                            )
                          "
                          class="form-check-input"
                          style="margin-right: 10px"
                          v-model="cadastrar[data.index]"
                        />
                        Cadastrar
                      </label>
                    </div>

                    <div
                      class="field col-12 md:col-3"
                      style="margin-bottom: 0rem"
                    >
                      <label class="form-check-label" v-if="data.data.especial">
                        <input
                          type="checkbox"
                          v-if="data.data.especial.editar == 1"
                          checked
                          @change="
                            modulo(
                              'editar',
                              data.data.permissao_modulo_id,
                              editar[data.index]
                            )
                          "
                          class="form-check-input"
                          style="margin-right: 10px"
                          v-model="editar[data.index]"
                        />
                        <input
                          type="checkbox"
                          v-else
                          @change="
                            modulo(
                              'editar',
                              data.data.permissao_modulo_id,
                              editar[data.index]
                            )
                          "
                          class="form-check-input"
                          style="margin-right: 10px"
                          v-model="editar[data.index]"
                        />
                        Editar
                      </label>
                      <label class="form-check-label" v-else>
                        <input
                          type="checkbox"
                          checked
                          @change="
                            modulo(
                              'editar',
                              data.data.permissao_modulo_id,
                              editar[data.index]
                            )
                          "
                          class="form-check-input"
                          style="margin-right: 10px"
                          v-model="editar[data.index]"
                        />
                        Editar
                      </label>
                    </div>

                    <div
                      class="field col-12 md:col-3"
                      style="margin-bottom: 0rem"
                    >
                      <label class="form-check-label" v-if="data.data.especial">
                        <input
                          type="checkbox"
                          v-if="data.data.especial.deletar == 1"
                          checked
                          @change="
                            modulo(
                              'deletar',
                              data.data.permissao_modulo_id,
                              deletar[data.index]
                            )
                          "
                          class="form-check-input"
                          style="margin-right: 10px"
                          v-model="deletar[data.index]"
                        />
                        <input
                          type="checkbox"
                          v-else
                          @change="
                            modulo(
                              'deletar',
                              data.data.permissao_modulo_id,
                              deletar[data.index]
                            )
                          "
                          class="form-check-input"
                          style="margin-right: 10px"
                          v-model="deletar[data.index]"
                        />
                        Deletar
                      </label>
                      <label class="form-check-label" v-else>
                        <input
                          type="checkbox"
                          checked
                          @change="
                            modulo(
                              'deletar',
                              data.data.permissao_modulo_id,
                              deletar[data.index]
                            )
                          "
                          class="form-check-input"
                          style="margin-right: 10px"
                          v-model="deletar[data.index]"
                        />
                        Deletar
                      </label>
                    </div>
                  </div>
                </template>
              </pm-Column>
            </pm-DataTable>
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <div class="grid">
          <pm-Button
            label="Voltar"
            @click="voltar()"
            icon="pi pi-angle-left"
            iconPos="right"
            style="margin-right: 20px"
          />
          <pm-Button
            label="Atualizar Login"
            @click="enviarDados()"
            icon="pi pi-check"
            iconPos="right"
            class="p-button-success"
            style="margin-right: 20px"
          />
          <pm-Button
            label="Remover Login"
            @click="removerLogin()"
            icon="pi pi-times-circle"
            iconPos="right"
            class="p-button-danger"
          />
        </div>
      </template>
    </pm-Card>

    <pm-Dialog v-model:visible="display2">
      <template #header>
        <h3>Editar número de telefone</h3>
      </template>

      <div class="row">
        <div class="field col-12 md:col-6">
          <label for="numero">Número</label>
          <pm-InputText
            id="numero"
            v-model="celular3"
            @keyup.enter="adicionarTelefone(2)"
            v-mask="{ mask: '(NN) NNNNN-NNNN', model: 'celular3' }"
          />
        </div>

        <div class="field col-12 md:col-6">
          <label for="identificador">Possuinte</label><br />
          <pm-Dropdown v-model="selected2" :options="selecIdentificador" />
        </div>
      </div>

      <template #footer>
        <pm-Button
          v-if="cadastrarOuEditarCelular == 2"
          label="Editar"
          icon="pi pi-check"
          autofocus
          @click="adicionarTelefone(2)"
        />
      </template>
    </pm-Dialog>
  </div>
</template>

<script>
import { Funcao } from "@/class/funcao.js";
import { Permissoes } from "@/class/permissoes.js";
import { Telefone } from "@/class/telefone.js";
import { Servidores } from "@/class/servidores.js";
import { Verificar } from "@/class/verificar.js";

export default {
  props: {
    dadosPessoa: { type: Array, default: () => [] },
    pg: {},
    id: {},
  },
  data() {
    return {
      emailInvalidoJaExiste: "",
      display2: false,
      errorColor: "",
      isNull: "",
      info: {
        nome: "",
        nomeMae: "",
        nomePai: "",
        possuiDeficiencia: false,
        deficiencia: "",
        nee: false,
        observacao: "",
        email: "",
        senha: "",
        matricula: null,
        rg: "",
        dataEmissao: "",
        cpf: "",
        dataNascimento: "",
        sexo: "",
        cep: "",
        logradouro: "",
        numero: "",
        complemento: "",
        bairro: "",
        cidade: "",
        estado: "",
        telefone: [],
        confirmarSenha: "",
        funcao: "",
      },
      funcaoALL: [],
      modulos: [],
      celular1: "",
      celular2: "",
      celular3: "",
      cadastrarOuEditarCelular: -1,
      indexNumeroEditado: -1,
      submitted: false,
      validationErrors: {},
      greenColor: "",
      visualizar: [],
      cadastrar: [],
      editar: [],
      deletar: [],
      selecIdentificador: ["Pessoal"],
      selected: "",
      selected2: "",
      funcaoUsuario: "",
    };
  },
  methods: {
    voltar() {
      this.redirecionarPessoa(0);
    },
    async enviarDados() {
      if (this.info.email == "") {
        alert("Campo Email está Vazio!");
        return false;
      }

      if (this.info.funcao == "") {
        alert("Selecione uma Função ao Usuario!");
        return false;
      }
      try {
        const data = await Servidores.alterarLogin(this.info);
        this.$vaToast.init({
          message: "Cadastrado com sucesso!",
          iconClass: "fa-star-o",
          position: "top-right",
          color: "success",
          duration: 2500,
          fullWidth: false,
        });
        this.redirecionarPessoa(0);
      } catch (error) {
        this.$vaToast.init({
          message: "Ocorreu um problema.",
          iconClass: "fa-star-o",
          position: "top-right",
          color: "danger",
          duration: 2500,
          fullWidth: false,
        });
      }
    },
    async removerLogin() {
      if (
        window.confirm(
          "Você realmente deseja remover os dados de acesso desta pessoa?"
        )
      ) {
        try {
          const data = await Servidores.deletarLogin(this.info);
          this.$vaToast.init({
            message: "Removido com sucesso!",
            iconClass: "fa-star-o",
            position: "top-right",
            duration: 2500,
            fullWidth: false,
            color: "success",
          });
          this.redirecionarPessoa(0);
        } catch (e) {
          if (e.response.data.error) {
            const a = e.response.data.error;
            this.$vaToast.init({
              message: a,
              iconClass: "fa-star-o",
              position: "top-right",
              duration: 2500,
              fullWidth: false,
              color: "#e42222",
            });
          } else {
            this.$vaToast.init({
              message: "Ocorreu um problema.",
              iconClass: "fa-star-o",
              position: "top-right",
              duration: 2500,
              fullWidth: false,
              color: "#e42222",
            });
          }
        }
      }
    },
    async verificarEmail() {
      if (this.info.email == "" || this.info.email == null) {
        this.emailInvalidoJaExiste = false;
        this.greenColor = "";
        return false;
      }

      const data = await Verificar.verificarJaExisteEmail(this.info.email);
      try {
        if (this.info.id == undefined) {
          if (data.data) {
            this.emailInvalidoJaExiste = true;
            this.greenColor = "border-color: #f44336;";
            return false;
          } else {
            this.greenColor = "border-color: #42d36f;";
            this.emailInvalidoJaExiste = false;
            return true;
          }
        } else if (
          this.info.id != undefined &&
          this.info.email !== this.info.emailDele
        ) {
          if (data.data) {
            this.emailInvalidoJaExiste = true;
            this.greenColor = "border-color: #f44336;";
            return false;
          } else {
            this.greenColor = "border-color: #42d36f;";
            this.emailInvalidoJaExiste = false;
            return true;
          }
        } else {
          this.emailInvalidoJaExiste = false;
          this.greenColor = "";
        }
      } catch (e) {
        this.emailInvalidoJaExiste = false;
        this.greenColor = "";
      }
    },
    isNumber(e) {
      const char = String.fromCharCode(e.keyCode); // Get the character
      if (/^[0123456789]+$/.test(char)) {return true;}
      // Match with regex
      else {e.preventDefault();} // If not match, don't add to input text
    },
    oTelefoneSeraCadastradoOuEditado(acao, celular) {
      if (acao == 2) {
        for (let i = 0; i < this.info.telefone.length; i++) {
          if (celular === this.info.telefone[i].nome) {
            this.indexNumeroEditado = i;
            this.celular2 = this.info.telefone[i];
            this.celular3 = this.info.telefone[i].nome;
            break;
          }
        }
      } else {
        this.celular1 = "";
      }
      this.cadastrarOuEditarCelular = acao;
      this.display2 = true;
    },
    async adicionarTelefone(acao) {
      let telVerificar = "";
      if (acao == 1) {
        telVerificar = this.celular1;
        this.selected;
      } else {
        telVerificar = this.celular3;
        this.selected;
      }

      telVerificar = telVerificar.replace(/[^0-9]/g, "");
      let telefone_form;

      for (let index = 0; index < telVerificar.length; index++) {
        if (index > 0) {
          if (index < 11) {
            telefone_form = `${telefone_form}${telVerificar[index]}`;
          }
        } else {
          telefone_form = `${telVerificar[index]}`;
        }
      }
      telVerificar = telefone_form;

      if (telVerificar.length < 9) {
        return false;
      }

      //verificar se o número ja existe no banco
      const jaExisteTelefone = await Telefone.obtemTelefone(telVerificar);
      if (jaExisteTelefone.data.telefone != undefined) {
        alert("O número digitado já existe na base de dados!");
        return false;
      } //Se tiver cadastrando
      if (this.info.id == undefined) {
        for (let i = 0; i < this.info.telefone.length; i++) {
          if (telVerificar === this.info.telefone[i]) {
            alert("O número inserido já foi adicionado a lista!");
            return false;
          }
        }
        if (acao == 1) {
          //verificação para obrigar que o usuário escolha um identificador caso o exista um número no input
          if (this.telVerificar != "" && this.selected == "") {
            this.isNull = true;
            this.errorColor = "border-color: #f44336;";
            return false;
          } else {
            this.isNull = false;
            this.errorColor = "";
            this.info.telefone.push({
              nome: telVerificar,
              identificador: this.selected,
            });
            this.celular1 = "";
            this.selected = "";
            return true;
          }
        } else {
          this.info.telefone[this.indexNumeroEditado].nome = telVerificar;
          this.celular3 = "";
        }
        this.display2 = false;
      } //Se tiver editando
      else {
        if (acao == 1) {
          if (this.telVerificar != "" && this.selected == "") {
            this.isNull = true;
            this.errorColor = "border-color: #f44336;";
            return false;
          } else {
            this.isNull = false;
            this.errorColor = "";
            const data = await Telefone.addTelefone(
              this.info.id,
              0,
              telVerificar,
              this.selected
            );
            this.celular1 = "";
            this.selected = "";
            this.atualizarTelefones();
          }
        } else {
          const novo = {
            id: this.celular2.id,
            telefone: telVerificar,
            identificador: this.selected2,
          };

          const data = await Telefone.editarTelefone(novo);
          this.celular2 = "";
          this.atualizarTelefones();
        }
        this.display2 = false;
      }
    },
    async buscarFuncaoALL() {
      try {
        const data = await Funcao.obtemTodos();
        for (const el of data.data) {
          const novo = {
            id: el.id,
            nome: el.nome,
          };
          if (el.id < 2 || el.id == 6) {
            continue;
          }
          this.funcaoALL.push(novo);
          this.funcaoALL.reverse();
        }
      } catch (e) {}
    },
    async deletarCelular(celular) {
      //Se for cadastrar
      if (this.info.id == undefined) {
        if (
          window.confirm("Você realmente deseja apagar o número selecionado?")
        ) {
          for (let i = 0; i < this.info.telefone.length; i++) {
            if (celular.nome === this.info.telefone[i].nome) {
              this.info.telefone.splice(i, 1);
              break;
            }
          }
        }
      } //Se for editar
      else {
        if (
          window.confirm("Você realmente deseja apagar o número selecionado?")
        ) {
          const data = await Telefone.removerTelefone(celular.id);
          this.atualizarTelefones();
        }
      }
    },
    async atualizarTelefones() {
      const data = await Servidores.obtemUm(this.info.id);
      const result = data.data;
      this.info.telefone = [];
      for (const el of result[0].telefone) {
        this.info.telefone.push({
          id: el.id,
          nome: el.telefone,
          identificador: el.identificador,
        });
      }
    },
    async modulo(tipo, mod, check) {
      let check_number;
      if (check) {
        check_number = 1;
      } else {
        check_number = 0;
      }

      if (tipo == "visualizar") {
        const payload = {
          login_id: this.info.login_id,
          funcao_id: this.info.funcao.id,
          permissao_modulo_id: mod,
          visualizar: check_number,
        };

        const tipo_env = 1;
        const data = await Permissoes.editarPermissaoUsuarioEspecial(
          tipo_env,
          payload
        );
      }
      if (tipo == "cadastrar") {
        const payload = {
          login_id: this.info.login_id,
          funcao_id: this.info.funcao.id,
          permissao_modulo_id: mod,
          cadastrar: check_number,
        };

        const tipo_env = 2;
        const data = await Permissoes.editarPermissaoUsuarioEspecial(
          tipo_env,
          payload
        );
      }
      if (tipo == "editar") {
        const payload = {
          login_id: this.info.login_id,
          funcao_id: this.info.funcao.id,
          permissao_modulo_id: mod,
          editar: check_number,
        };

        const tipo_env = 3;
        const data = await Permissoes.editarPermissaoUsuarioEspecial(
          tipo_env,
          payload
        );
      }
      if (tipo == "deletar") {
        const payload = {
          login_id: this.info.login_id,
          funcao_id: this.info.funcao.id,
          permissao_modulo_id: mod,
          deletar: check_number,
        };

        const tipo_env = 4;
        const data = await Permissoes.editarPermissaoUsuarioEspecial(
          tipo_env,
          payload
        );
      }
    },
    redirecionarPessoa(entrouNaPagina) {
      // console.log(this.id);
      if (entrouNaPagina) {
        if (
          this.id == null &&
          (this.funcaoUsuario == 1 || this.funcaoUsuario == 2)
        ) {
          this.$router.push({ name: "servidores", params: { pg: this.pg } });
        } else if (
          this.id == null &&
          (this.funcaoUsuario == 3 || this.funcaoUsuario == 4)
        ) {
          this.$router.push({
            name: "coordenacao-servidores",
            params: { pg: this.pg },
          });
        }
      } else {
        if (this.funcaoUsuario == 1 || this.funcaoUsuario == 2) {
          this.$router.push({ name: "servidores", params: { pg: this.pg } });
        } else if (this.funcaoUsuario == 3 || this.funcaoUsuario == 4) {
          this.$router.push({
            name: "coordenacao-servidores",
            params: { pg: this.pg },
          });
        }
      }
    },
  },

  async beforeMount() {
    this.funcaoUsuario = sessionStorage.getItem("funcaoDoUsuario");
    this.redirecionarPessoa(1);
    await this.buscarFuncaoALL();
    const data1 = await Servidores.obtemUm(this.id);
    const result = data1.data;
    this.dadosPessoa.id = result[0].id;
    this.dadosPessoa.login_id = result[0].login_id;
    this.dadosPessoa.nome = result[0].nome;
    this.dadosPessoa.sobrenome = result[0].sobrenome;
    this.dadosPessoa.nomeMae = result[0].nome_mae;
    this.dadosPessoa.nomePai = result[0].nome_pai;
    this.dadosPessoa.observacao = result[0].observacao;
    this.dadosPessoa.email = result[0].email;
    this.dadosPessoa.certidaoNascimento = result[0].certidao_nascimento;
    this.dadosPessoa.certidaoCasamento = result[0].certidao_casamento;
    this.dadosPessoa.rg = result[0].rg;
    this.dadosPessoa.rgDataEmissao = result[0].rg_data_missao;
    this.dadosPessoa.cpf = result[0].cpf;
    this.dadosPessoa.cpfDele = result[0].cpf;
    this.dadosPessoa.dataNascimento = result[0].nascimento;
    this.dadosPessoa.sexo = result[0].sexo;
    this.dadosPessoa.matricula = result[0].matricula;
    this.dadosPessoa.observacao = result[0].observacao;
    this.dadosPessoa.possuiDeficiencia = result[0].deficiencia;
    this.dadosPessoa.deficiencia = result[0].qual_deficiencia;
    this.dadosPessoa.cep = result[0].endereco.cep;
    this.dadosPessoa.logradouro = result[0].endereco.logradouro;
    this.dadosPessoa.numero = result[0].endereco.numero;
    this.dadosPessoa.complemento = result[0].endereco.complemento;
    this.dadosPessoa.bairro = result[0].endereco.bairro;
    this.dadosPessoa.cidade = result[0].endereco.cidade;
    this.dadosPessoa.estado = result[0].endereco.estado;
    this.dadosPessoa.nee = result[0].nee;
    this.dadosPessoa.email = result[0].login.email;
    this.dadosPessoa.emailDele = result[0].login.email;
    this.dadosPessoa.telefone = [];
    this.dadosPessoa.id = result[0].id;
    this.dadosPessoa.login_id = result[0].login_id;
    this.dadosPessoa.escolaridade = result[0].escolaridade;
    this.dadosPessoa.jaBuscou = true;
    // console.log(this.dadosPessoa)
    for (const el of this.funcaoALL) {
      if (result[0].login.funcao == el.id) {this.dadosPessoa.funcao = el;}
    }
    for (const el of result[0].telefone) {
      this.dadosPessoa.telefone.push({
        id: el.id,
        nome: el.telefone,
        identificador: el.identificador,
      });
    }

    //await this.buscarFuncaoALL();
    this.info.nome =
      this.dadosPessoa.nome != undefined
        ? this.dadosPessoa.nome
        : this.info.nome;
    this.info.sobrenome =
      this.dadosPessoa.sobrenome != undefined
        ? this.dadosPessoa.sobrenome
        : this.info.sobrenome;
    this.info.possuiDeficiencia = this.dadosPessoa.possuiDeficiencia;
    this.info.deficiencia = this.dadosPessoa.deficiencia;
    this.info.observacao = this.dadosPessoa.observacao;
    this.info.nee = this.dadosPessoa.nee;
    this.info.email = this.info.email =
      this.dadosPessoa.email != undefined
        ? this.dadosPessoa.email
        : this.info.email;
    this.info.emailPadrao = this.dadosPessoa.emailPadrao;
    this.info.senha =
      this.dadosPessoa.senha != undefined
        ? this.dadosPessoa.senha
        : this.info.senha;
    this.info.confirmarSenha =
      this.dadosPessoa.confirmarSenha != undefined
        ? this.dadosPessoa.confirmarSenha
        : this.info.confirmarSenha;
    this.info.rg = this.dadosPessoa.rg;
    this.info.rgDataEmissao =
      this.dadosPessoa.rgDataEmissao != undefined
        ? this.dadosPessoa.rgDataEmissao
        : this.info.rgDataEmissao;
    this.info.cpf = this.dadosPessoa.cpf;
    this.info.cpfDele =
      this.dadosPessoa.cpfDele != undefined ? this.dadosPessoa.cpfDele : "";
    this.info.dataNascimento =
      this.dadosPessoa.dataNascimento != undefined
        ? this.dadosPessoa.dataNascimento
        : this.info.dataNascimento;
    this.info.cep =
      this.dadosPessoa.cep != undefined ? this.dadosPessoa.cep : this.info.cep;
    this.info.estado = this.dadosPessoa.estado;
    this.info.cidade = this.dadosPessoa.cidade;
    this.info.bairro = this.dadosPessoa.bairro;
    this.info.logradouro = this.dadosPessoa.logradouro;
    this.info.numero = this.dadosPessoa.numero;
    this.info.complemento = this.dadosPessoa.complemento;
    this.info.telefone =
      this.dadosPessoa.telefone == undefined ? [] : this.dadosPessoa.telefone;
    this.info.sexo =
      this.dadosPessoa.sexo != undefined
        ? this.dadosPessoa.sexo
        : this.info.sexo;
    this.info.foto = this.dadosPessoa.foto;
    this.info.id =
      this.dadosPessoa.id != undefined ? this.dadosPessoa.id : this.info.id;
    this.dadosPessoa.jaBuscou = true;
    this.info.matricula = this.dadosPessoa.matricula;
    this.info.escolaridade = this.dadosPessoa.escolaridade;
    this.info.funcao =
      this.dadosPessoa.funcao != undefined
        ? this.dadosPessoa.funcao
        : this.info.funcao;
    this.info.login_id = this.dadosPessoa.login_id;
    this.info.emailDele = this.dadosPessoa.emailDele;

    // console.log(this.dadosPessoa)
    // console.log(this.info)

    const data = await Permissoes.obtemUsuarioEspecialpermissao(
      this.info.login_id,
      this.info.funcao.id
    );
    this.modulos = data.data;
  },
};
</script>
